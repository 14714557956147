.animation-icon{
  transition:0.3s; /* Transition duration */
}
.animation-icon-animate{
  -o-transform:rotate(180deg);
  -ms-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg); /* How many times it'll rotate = degrees÷360 */
}
