@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

:root {

  /* Colors: */
  --unnamed-color-0b141f: #0B141F;
  --unnamed-color-a4a4a4: #A4A4A4;
  --unnamed-color-333333: #333333;
  --unnamed-color-737373: #737373;
  --unnamed-color-5ec4ff: #5EC4FF;

  /* Font/text values */
  --unnamed-font-family-open-sans: Open Sans;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-600: 600px;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-28: 28px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing-0-03: 0.03px;
  --unnamed-character-spacing-0-28: 0.28px;
  --unnamed-character-spacing-0-7: 0.7px;
  --unnamed-character-spacing-0-04: 0.04px;
  --unnamed-line-spacing-19: 19px;
  --unnamed-line-spacing-22: 22px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-38: 38px;
  --unnamed-decoration-underline: underline;
  }

  /* Character Styles */
  .breadcrumb {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0-03);
  color: var(--unnamed-color-a4a4a4);
  }
  .preenchimento-dos-inputs {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0-03);
  color: var(--unnamed-color-333333);
  }
  .aba-secundária {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0-03);
  color: var(--unnamed-color-a4a4a4);
  }
  .texto-padrão {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0-03);
  color: var(--unnamed-color-333333);
  }
  .colunas-tabela {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0-28);
  color: var(--unnamed-color-a4a4a4);
  }
  .labels {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0-7);
  color: var(--unnamed-color-737373);
  }
  .aba-selecionada {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0-03);
  color: var(--unnamed-color-333333);
  }
  .botão {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-04);
  color: var(--unnamed-color-333333);
  }
  .links {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-04);
  color: var(--unnamed-color-3d7ab8);
  text-decoration: var(--unnamed-decoration-underline);
  }
  .título {
  font-family: var(--unnamed-font-family-open-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-28);
  line-height: var(--unnamed-line-spacing-38);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-333333);
  }
