.whitecard {
    padding: 15px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 0;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 5px;
    min-height: 95vh;
    flex: 1;
    flex-wrap: wrap;
  }

  .loading-spinner {
    align-self: center;
    justify-self: center;
    flex: 1;
  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
  }
  
  .tooltip-grafico {
    padding: 10px;
  }

 #buttonPointer {
    cursor: pointer;
  }

  .limparButton {
    color: red;
    cursor: pointer;
    margin: 0px 0px 10px 8px;
}

.containerLimpar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.erro-dropdown {
  border: 1px solid red !important;
}