.required:after {
    content:" *"; 
    color: red;
  }

  input#sequencia.form-control{
    width: 10%;
    height: 50%;
}

select#motivo.form-control{
    width: 30%;
    height: 50%;
}

input#local.form-control{
  width: 40%;
  height: 50%;
}

input#cep.form-control{
  width: 40%;
  height: 50%;
}

input#logradouro.form-control{
  width: 80%;
  height: 50%;
}

input#numero.form-control{
  width: 15%;
  height: 50%;
}

input#complemento.form-control{
  width: 80%;
  height: 50%;
}

input#bairro.form-control{
  width: 30%;
  height: 50%;
}

select#idCodigoIbge.form-control{
  width: 10%;
  height: 50%;
}

select#cidade.form-control{
  width: 30%;
  height: 50%;
}