.alert-row {
    /* background-color: rgb(252, 210, 210); */
    background: linear-gradient(270deg, rgb(255, 184, 184) , rgba(255, 255, 255, 0) 100%);
  }
  
  .success-row {
    /* background-color: rgb(223, 255, 223); */
    background: linear-gradient(270deg, rgb(184, 255, 184) , rgba(255, 255, 255, 0) 100%);
  }

   
  .attention-row {
    background: linear-gradient(270deg, rgb(255, 250, 184) , rgba(255, 255, 255, 0) 100%);
  }

  .attention-row:hover {
    background: linear-gradient(270deg, rgb(255, 242, 129) , rgba(255, 255, 255, 0) 100%);
  }
  
  .alert-row:hover {
    background: linear-gradient(270deg, rgb(255, 149, 149) , rgba(255, 255, 255, 0) 100%);
    /* background-color: rgb(255, 184, 184); */
  }
  
  .success-row:hover {
    background: linear-gradient(270deg, rgb(144, 255, 144) , rgba(255, 255, 255, 0) 100%);
    /* background-color: rgb(184, 255, 184); */
  }
  
