label.required.form-label{
   font-size: 14px;
   color: #737373;
}

input#nome.form-control{
    width: 60%;
}

small.form-text{
    font-size: 18px;
    margin-left: 1%;
}

input#descricaoOrigem.form-control{
    width: 60%;
    height: 50%;
}

input#cepOrigem.form-control{
    width: 20%;
    height: 50%;
}

input#logradouroOrigem.form-control{
    width: 60%;
    height: 50%;
}

input#numeroOrigem.form-control{
    width: 15%;
    height: 50%;
}

label.form-label{
    font-size: 14px;
    color: #737373;
 }

 input#complementoOrigem.form-control{
    width: 50%;
    height: 50%;
}

input#bairroOrigem.form-control{
    width: 25%;
    height: 50%;
}

select#idUfOrigem.form-control{
    width: 15%;
    height: 50%;
}

select#cidadeOrigem.form-control{
    width: 50%;
    height: 50%;
}

input#descricaoDestino.form-control{
    width: 60%;
    height: 50%;
}

input#cepDestino.form-control{
    width: 20%;
    height: 50%;
}

input#logradouroDestino.form-control{
    width: 60%;
    height: 50%;
}

input#numeroDestino.form-control{
    width: 15%;
    height: 50%;
}

 input#complementoDestino.form-control{
    width: 50%;
    height: 50%;
}

input#bairroDestino.form-control{
    width: 25%;
    height: 50%;
}

select#idUfDestino.form-control{
    width: 15%;
    height: 50%;
}

select#cidadeDestino.form-control{
    width: 50%;
    height: 50%;
}
