input#paisOrigem.form-control{
    width: 25%;
    height: 50%;
}

input#paisDestino.form-control{
    width: 25%;
    height: 50%;
}

input#ufInternacionalOrigem.form-control{
    width: 15%;
    height: 50%;
}

input#cidadeInternacionalOrigem.form-control{
    width: 50%;
    height: 50%;
}

input#ufInternacionalDestino.form-control{
    width: 15%;
    height: 50%;
}

input#cidadeInternacionalDestino.form-control{
    width: 50%;
    height: 50%;
}