.containerProfile img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 200px;
}

.containerProfile {
  display: flex;
  margin-right: 40px;
  width: 45%;
  justify-content: flex-end;
}

.containerPerfil {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.column1 {
  width: 50%;
}

.column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
