.titulo-container-cadastroSat {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.subtitulo-cadastroSat {
  font-size: 14px;
  margin-left: 80px;
  margin-top: 5px;
  color: #a4a4a4;
}

.titulo-cadastroSat {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-28) / var(--unnamed-line-spacing-38)
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-333333);
  text-align: left;
  font: normal normal 600 28px/38px Open Sans;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.table-sat-title {
  font-size: 18px;
  font-family: 'Open Sans';
  color: #333333;
}

.tables-sat-container {
  display: flex;
  justify-content: space-between;
}

.table-sat {
  margin-left: 20px;
  margin-right: 20px;
}
.table-sat-row:hover {
  color: #a4a4a4;
}

.text-input-container {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom-style: solid;
  border-bottom-width: 0.1px;
  border-bottom-color: #cecece;
}

.text-input-container-separated {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-style: solid;
  border-bottom-width: 0.1px;
  border-bottom-color: #cecece;
}

.formGroupInput {
  min-width: 180px;
  width: 15%;
}

.cadastro-sat-titulo {
  font-size: 18px;
  font-family: 'Open Sans';
  color: #333333
}

.campo-obrigatorio {
  color: red;
}

.table-dispositivos {
  margin-top: 30px;
  align-self: center;
  max-width: 1000px;
  border-style: solid;
  border-color: #cecece;
}

.table-dispositivos-title {
  margin-bottom: 20px;
}

.titulo-dispositivos {
  margin-top: 40;
}

.responsavel-input {
  margin-bottom: 40px;
}

.table-dispositivos-row-active {
  background-color: rgb(181, 255, 181);
}

.button-container {
  justify-content: flex-end;
  display: flex;
  padding: 20px;
  margin: 20px;
}

.table-sat-flex {
  display: flex;
  justify-content: space-between;
}

.responsavel-error {
  color: red;
}

.veiculos-meiotransporte-container {
  display: flex;
  flex-direction: column;
}
.dropdown-meiotransporte {
  width: 15%;
  min-width: 180px;
  margin-bottom: 20px;
}

.meiotransporte-title {
  margin-bottom: 5px;
  margin-top: 5px;
  font-family: 'Open Sans';
}
.tipo-frota {
  margin-top: 20px;
}
