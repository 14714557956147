tbody td {
  color: #333333;
  font-family: 'Open Sans';
  border-bottom: 2px solid #dedede;
}

tbody tr:hover {
  background-color: rgb(236, 236, 236);
}

#icons-container {
  text-align: right;
  vertical-align: middle;
}

thead {
  border-top: 2px solid transparent;
}

thead th {
  color: #a4a4a4;
  font-size: 14px;
  font-family: 'Open Sans';
}

table {
  border: 1px;
}

.situacao {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}
