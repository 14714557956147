input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance:textfield;
}


.container-buttons{
    margin-top:1.5rem;
}

     .container-buttons.form-row > .form-group.col-md-2 {
        text-align: right;
        margin-right: 2rem;
    }

     .container-buttons.form-row > .form-group.col-md-2 > .btn-enviar{
        width: 183px;
        height: 50px;
        background: #FFDA53 0% 0% no-repeat padding-box;
        border: 2px solid #FFDA53;
        color: #0B141F;
        border-radius: 5px;
        opacity: 1;
        font-weight: 400;
    }

