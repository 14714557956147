#progressao-linha {
  width: 80%;
}

.progressao-cabecalho-texto {
  margin-right: 40px;
  font-size: 14px;
}

.progressao-cabecalho {
  background-color: #e8faff;
  flex: 1;
}

.progressao-body-texto {
  margin-right: 40px;
  font-size: 14px;
}

.progresso-ponto {
  border: 1px solid black;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  background-color:white;
  z-index: 1;
}

.timeline {
  display: flex;
  height: 50px;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.progresso-linha {
  height: 18px;
  margin-left: -5px;
  margin-right: -5px;
  flex: 1;
  background-color: #e0e0e0;
}

.progresso-linha-colored {
  background-color: blue;
  height: 18px;
}

.textura {
  /* background-image: url("https://www.transparenttextures.com/patterns/always-grey.png"); */
  /* background-image: url("https://www.transparenttextures.com/patterns/gray-lines.png"); */
  background-image: url("https://www.transparenttextures.com/patterns/black-twill.png");
}

#tr-acompanhamento {
  flex: 1;
  flex-direction: row;
}

#destino-header {
  text-align: end;
}

.pointer :hover {
  cursor: pointer;
}
