

@media  (max-width: 700px){
    
    .menu-lateral {
    background: red;
    display: none;
}
}

.openMenu {
    border-radius: 30px;
    background-color: white;
    margin: 18px -10px 0 8px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
            background: #f8f9fa;
            outline: none;
    border: 1px solid #dee2e6;
}

.openMenu:hover {
    background: #e9ecef;
}